<script>
import vuetableFormatter from '@src/mixins/vuetableFormatters'

const i18nKey = 'EXPORT_JOBS'

export default {
    mixins: [vuetableFormatter],
    data() {
        return {
            exportJobFields: [
                {
                    name: 'created_at',
                    title: this.getI18n('COMMON', 'created_at'),
                    sortField: 'created_at',
                },
                {
                    name: 'user.name',
                    title: this.getI18n(i18nKey, 'FIELDS.creator'),
                    sortField: 'users|users.name',
                },
                {
                    name: 'type',
                    title: this.getI18n(i18nKey, 'FIELDS.type'),
                    sortField: 'type',
                    formatter: this.exportTypes,
                },
                {
                    name: 'status',
                    title: this.getI18n(i18nKey, 'FIELDS.status'),
                    sortField: 'status',
                    formatter: this.importStatuses,
                },
                {
                    name: 'started_at',
                    title: this.getI18n(i18nKey, 'FIELDS.started_at'),
                    sortField: 'started_at',
                },
                {
                    name: 'ended_at',
                    title: this.getI18n(i18nKey, 'FIELDS.ended_at'),
                    sortField: 'ended_at',
                },
                {
                    name: 'file',
                    title: this.getI18n(i18nKey, 'FIELDS.file'),
                },
            ],
        }
    },
    methods: {
        importStatuses(value) {
            return this.getI18n(i18nKey, `STATUSES.${value}`)
        },

        exportTypes(value) {
            return this.getI18n(i18nKey, `TYPES.${value}`)
        },
    },
}
</script>
